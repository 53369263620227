<template>
  <b-modal
    id="PrivacyPolicyShow"
    title-class="titleMsg"
    body-class="modalBox"
    header-class="privacyModalHeader"
    content-class="p-5"
    title="個人情報保護方針"
    size="xl"
    title-tag="h2"
    ok-title = "閉じる"
    ok-variant="secondary"
    :hide-header-close="true"
    :centered="true"
    :ok-only="true"
  >
    <div class="title-message">
      KDDI財団は、KDDIグループの一員として、財団の情報資産 (KDDIグループに関する情報を含む) を守るため、以下の対策を実施しています。
    </div>
    <div class="privacyPolicy">
      <p class="headMsg">1. 個人情報の収集、利用および提供</p>
      <span class="pl-20">
        当財団は、個人情報の収集目的を明確に定め、財団の正当な事業の範囲内で収集し、収集目的の範囲内において利用します。
        収集目的の範囲を超える利用または第三者に提供を行うのは、情報主体からの明確な同意が得られた場合に限ります。
      </span>
      <p class="headMsg">2. 個人情報の適正管理</p>
      <span class="pl-20">
        当財団は、個人情報の正確性および利用の安全性を確保し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざんおよび漏えい等の予防ならびに是正に関する安全対策を実施します。
      </span>
      <p class="headMsg">3. 法令遵守</p>
      <span class="pl-20">
        当財団は、個人情報に関する法令、規格およびその他の規範を遵守します。
      </span>
    </div>
  </b-modal>

</template>
<script>

export default {
  name: 'PrivacyPolicyModal',
};
</script>
<style>
.modalBox{
  padding: 25px;
}
.privacyModalHeader {
  border-bottom-color: #A0A9D0 !important;
  padding: 0 !important;
}
</style>
<style scoped>

.titleMsg {
  font-size: 26px !important;
}
.headMsg {
  font-size: 24px !important;
}
.privacyPolicy {
  margin-top: 20px;
}
.privacyPolicy p{
  font-size: 28px;
  margin:5px;
  display: block;
}
.title-message, .privacyPolicy span{
  display: block;
  font-size: 16px;
}
.privacyPolicy span{
  margin:10px 0;
}
.pl-20{
  padding-left: 20px;
}
</style>
