<template>
  <div id="RegisterForm" class="wrap" v-if="!isLoadingNow">
    <div class="formWrap contentsWidth mx-auto" v-if="!hasSend">
      <h1 class="title">マイページ登録</h1>
      <div class="notice-wrap mb-2">
        <p class="mb-0">必要項目をご記入の上、登録ボタンをクリックしてください。</p>
        <p class="mb-0">環境依存文字の使用はお控えください。</p>
        <p class="mb-0">本フォームはメールアドレス登録後、2時間有効です。</p>
        <p class="mb-0">登録が完了しなかった場合は、お手数ですが最初からお試しください。</p>
        <p class="mb-0">社会的・文化的諸活動助成と国際会議開催助成プログラム申請の場合は連絡担当者の情報を入力してください</p>
      </div>
      <b-form>
        <div class="">
          <hr>

          <b-row class="textBase">
            <b-col class="labelWrap">
              <label>メールアドレス<br>(ログインID)</label>
            </b-col>
            <b-col class="textData">
              {{ mailAdress }}
            </b-col>
          </b-row>
          <hr>

          <v-form-text
            label="パスワード"
            type="password"
            itemStyle="line"
            inputClass="w-long"
            :errMessage="errors.password || []"
            v-model="inputData.password"
            maxLength="255"
            @check = 'passCheck'
            :isRequired="true"
            notice="英大文字・英小文字・数字・記号をそれぞれ1つ以上利用し8文字以上入力してください。"
          />
          <v-form-text
            label="パスワードの確認"
            type="password"
            itemStyle="line"
            inputClass="w-long"
            :errMessage="errors.password_conf || []"
            v-model="inputData.password_conf"
            maxLength="255"
            @check = 'passCheck'
            :isRequired="true"
          />
          <hr>

          <v-form-name
            label="氏名"
            :left.sync="inputData.sei"
            :right.sync="inputData.mei"
            :placeholders="{left: '例）山田' , right: '例）太郎'}"
            :errors="{left: errors.sei || '', right: errors.mei || ''}"
            :isRequired="true"
          />
          <hr>

          <v-form-name
            label="ふりがな"
            :left.sync="inputData.kana_sei"
            :right.sync="inputData.kana_mei"
            :placeholders="{left: '例）やまだ' , right: '例）たろう'}"
            :errors="{left: errors.kana_sei || '', right: errors.kana_mei || ''}"
            :isRequired="true"
          />
          <hr>

          <v-form-birth
            label="生年月日"
            name="birth"
            :placeholders="{year: '例）1980', month: '例）10', day: '例）10'}"
            :errMessage="errors.birth || []"
            v-model="inputData.birth"
            :isRequired="true"/>
          <hr>

          <v-form-text
            label="所属機関（大学、団体名等）"
            itemStyle="line"
            inputClass="w-long"
            maxLength="500"
            placeholder="例）国際大学"
            :isRequired="true"
            :errMessage="errors.institution || []"
            v-model="inputData.institution"
          />
          <hr>

        </div>
        <div class="approvalWrap text-decoration-underline">
          <b-link
            v-b-modal.PrivacyPolicyShow>助成金業務支援システムの個人情報の取扱について</b-link>
          <b-form-checkbox
            v-model="inputData.approval"
            value="1"
            class="approvalBox"
            id="approval"
            size="lg"
          >
            <span>個人情報の取扱について同意します</span>
          </b-form-checkbox>
        </div>
        <b-alert
        class="errorWrap"
        :show="typeof errors.errorMessage !== 'undefined'"
        variant="warning">{{errors.errorMessage}}</b-alert>
        <b-button
          class="regist"
          :disabled="(!inputData.approval || hasPassErr)"
          @click="send"
        >
          登録
        </b-button>
      </b-form>
    </div>
    <div class="end" v-if="hasSend">
      <h1>登録が完了しました</h1>
      <b-button
        class="btn fw-6"
        @click="$router.push({ name: 'gasLogin' })"
      >
        ログイン画面へ
      </b-button>
    </div>

    <!-- popUp -->
    <v-pop-privacy-policy></v-pop-privacy-policy>
  </div>
</template>

<script>
import privacyPolicyModal from '@/components/parts/PrivacyPolicyModal.vue';
import api from '@/modules/api';
import CONST_USER_INFO from '@/constants/userInfo';

export default {
  name: 'RegisterForm',
  components: {
    'v-pop-privacy-policy': privacyPolicyModal,
  },
  data() {
    return {
      mailAdress: null,
      inputData: {
        password: null,
        password_conf: null,
        sei: null,
        mei: null,
        kana_sei: null,
        kana_mei: null,
        birth: null,
        institution: null,
      },
      errors: [],
      isLoadingNow: true,
      hasSend: false,
      hasPassErr: false,
    };
  },
  methods: {
    async send() {
      this.$store.dispatch('page/onWaiting');
      const { uuid } = this.$route.params;
      const params = Object.assign(this.inputData, { uuid });
      const res = await api.send('/api/user/register/store', params)
        .catch((error) => {
          this.errors = error.response.data.message;
          return false;
        });
      if (res !== false) {
        this.hasSend = true;
      }
      this.$store.dispatch('page/offWaiting');
    },
    passCheck() {
      this.$delete(this.errors, 'password_conf');
      this.hasPassErr = false;
      if (!this.inputData.password || !this.inputData.password_conf) {
        return true;
      }
      if (this.inputData.password !== this.inputData.password_conf) {
        this.$set(this.errors, 'password_conf', ['パスワードの確認とパスワードが一致するよう入力してください。']);
        this.hasPassErr = true;
        return false;
      }
      return true;
    },
  },
  computed: {
    sex_option() {
      return CONST_USER_INFO.SEX_OPTION;
    },
  },
  async created() {
    this.$store.dispatch('page/onWaiting');
    const { uuid } = this.$route.params;
    const params = { uuid };
    const res = await api.send('/api/user/register/getMailAddress', params)
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'gasLogin' });
        return false;
      });
    if (res !== false) {
      this.mailAdress = res.data.mailAddress;
      this.isLoadingNow = false;
    }
    this.$store.dispatch('page/offWaiting');
  },
};
</script>


<style scoped>
.title{
  margin-top: 52px;
  margin-bottom: 16px;
  color: #333333;
  font-weight: 600;
  font-size: 24px;
}
.formWrap{
  text-align: center;
}
.inputBox{
  width: 700px;
  margin: 0 auto;
  padding-bottom: 27px;
  border-bottom: solid 1px #CCCCCC;
}
.textBase{
  text-align: left;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  margin:15px auto;
}
.textBase label {
  width: 237px;
}
.textBase .labelWrap {
  max-width: 300px;
  font-weight: bold;
}
.textBase label{
  padding-left: 51px;
  text-indent:-0.5em;
  margin-bottom:0;
}
.textBase .labelWrap {
  margin-right: 0;
  padding-right: 0;
  text-align: left;
}
.textBase .textData{
  padding-left: 20px;
}
.approvalWrap{
  margin-top:30px;
}

.approvalBox{
  margin:30px 0;
}
.approvalWrap label span{
  display: block;
  margin-top: 3px;
}
.regist,
.end button.btn{
  background-color: #122889!important;
  color: #FFFFFF!important;
  font-size: 18px;
  font-weight: 600;
  margin: 10px;
}
/* 完了画面 */
.end{
  width: 280px;
  margin:auto;
  margin-top: 32.8vh;
  text-align: center;
}
.end h1{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 46px;
}
span.guide{
  margin-left: 333px;
  display: block;
  text-align: left;
  width: 400px;
  font-size: 13px;
  margin-bottom: -11px;
}
</style>
<style>
  #RegisterForm .custom-control-label::before{
    border-radius: 0!important;
    border: solid 2px #6E6E6E!important;
  }
  #RegisterForm .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #122889;
  }
  #RegisterForm .errorWrap{
    width: 700px;
    margin:0 auto;
    margin-bottom: 30px;
  }
</style>
